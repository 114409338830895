import React, {createContext, useContext, useState} from 'react';
import Cookies from 'js-cookie';
import translations from '../locales/translation';
import {useRouter} from 'next/router';

const LanguageContext = createContext();

const DEFAULT_LANGUAGE = 'tr';

// Language Provider
export function LanguageProvider({children}) {
  const router = useRouter();
  const language = Cookies.get('selectedLanguage') || DEFAULT_LANGUAGE;
  const [selectedLanguage, setSelectedLanguage] = useState(language); // Default or cookie lang

  const changeLanguage = async lang => {
    await Cookies.set('selectedLanguage', lang);
    await router.push('/');
    setSelectedLanguage(lang);
  };

  function translate(key) {
    return translations[selectedLanguage][key];
  }

  return <LanguageContext.Provider value={{selectedLanguage, changeLanguage, translate}}>{children}</LanguageContext.Provider>;
}

// Language Hook
export function useLanguage() {
  return useContext(LanguageContext);
}
