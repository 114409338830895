import {getHeader, getFooter} from '@/store/services';

const withDefaultprops = async (store, context, callback = undefined) => {
  const {dispatch} = store;
  try {
    const language = context.req.cookies?.selectedLanguage || 'tr';
    await Promise.all([
      await dispatch(getHeader.initiate(language)),
      await dispatch(getFooter.initiate(language)),
      await callback?.(language, store, context),
    ])
      .then(r => {
        // Check 404
        if (r.pop?.()?.notFound) throw {notFound: true};
        // Map Response
        r?.map?.(item => {
          if (item?.StatusCode === 404) throw {notFound: true};
          if (item?.status === 'rejected' || item?.error?.name === 'AbortError' || item?.error?.originalStatus === 503) {
            throw Error({...item.error, StatusCode: item?.error?.originalStatus ?? item?.StatusCode});
          }
        });
      })
      .catch(e => {
        throw e;
      });

    return {props: {language}};
  } catch (error) {
    // 301-302 Redirection
    if (error?.redirect) return {redirect: error.redirect};
    // 404 Redirection
    if (error?.notFound) return {notFound: true};

    // 500 Error
    if (context?.res) context.res.statusCode = error?.StatusCode ?? 500;
    return {props: {errorCode: 500}};
  }
};
export default withDefaultprops;
