import classNames from 'classnames';
import Image from 'next/image';
import {useCallback, useEffect, useState} from 'react';
import {useGetHeaderQuery} from '@/store/services';
import Links from '@/components/links';
import {selectedLanguage} from '@/hooks/LanguageContext';
import {useRouter} from 'next/router';
import {fileDomain} from '@/config/constants';

const MobileMenu = ({show = false, onHide}) => {
  const router = useRouter();
  const {data} = useGetHeaderQuery(selectedLanguage ? selectedLanguage : 'tr');
  const [activeMenu, setActiveMenu] = useState(null);
  const [showMenu, setShowMenu] = useState(show);
  const roots = data?.map(item => item.Items).flat();

  useEffect(() => {
    const handleRouteComplete = () => closeMenu();
    router.events.on('routeChangeComplete', handleRouteComplete);
    return () => {
      router.events.off('routeChangeComplete', handleRouteComplete);
    };
  });

  const closeAndResetMenu = useCallback(() => {
    if (activeMenu) {
      setActiveMenu(null);
    } else {
      setShowMenu(false);
      onHide(false);
    }
  }, [activeMenu, onHide]);

  const closeMenu = useCallback(() => {
    setShowMenu(false);
    setActiveMenu(null);
    onHide(false);
  }, [showMenu, onHide, activeMenu]);

  useEffect(() => {
    setShowMenu(show);
    show ? document.body.classList.add('fixedbar') : document.body.classList.remove('fixedbar');
  }, [show]);

  return (
    <div
      className={classNames(
        'fixed top-0 overflow-hidden overflow-y-auto left-0 w-full h-full bg-white z-[55] flex flex-col transition-all invisible opacity-0 lg:hidden',
        {
          '!visible !opacity-100': showMenu,
        },
      )}>
      <div className="flex px-[24px] py-[16px] items-center h-[64px] flex-grow-0">
        <button className="mr-[16px] text-[24px] m-0 flex w-[24px]" onClick={closeAndResetMenu}>
          <i
            className={classNames('icon rotate-180', {
              'right-chevron': activeMenu,
              close: !activeMenu,
            })}
          />
        </button>
        <div className="relative max-w-[116px]">
          <Image src={`${fileDomain}/public/boyner-corporate/logo.png`} width={116} height={16} alt=""></Image>
        </div>
      </div>
      <div className="pt-[32px] px-[24px] relative text-primary flex-1">
        <ul>
          {roots?.map(({Title, Url, Items}, index) => (
            <li className="pb-[32px]" key={index}>
              <div className="text-[24px] leading-[34px]" onClick={() => setActiveMenu(index + 1)}>
                {Title}
              </div>
              <div
                className={classNames(
                  'absolute bg-white top-0 overflow-y-auto w-full h-full z-9 pt-[32px] px-[24px] transition-all -right-[100%] invisible opacity-0',
                  {
                    '!visible !opacity-100 !right-0': activeMenu === index + 1,
                  },
                )}>
                <ul>
                  <li className="pb-[32px]" key={index}>
                    <Links href={Url} className="text-[24px] leading-[34px] font-boldie">
                      {Title}
                      <i className="icon text-shape-right text-[12px] ml-[12px] text-active" />
                    </Links>
                  </li>
                  {Items?.map(({Text, Url}, index) => (
                    <li className="pb-[32px] pl-[17px]" key={index}>
                      <Links href={Url} className="text-[24px] leading-[34px]">
                        {Text}
                      </Links>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/*       <div className="h-[72px] w-full bg-primary sticky text-[#999999] flex items-center justify-center text-[14px] leading-[20px]">
        <Links href="#" className="border-r-[1px] border-r-[#D0D1D3] px-[48px] font-boldie text-[#ffffff]">
          TR
        </Links>
        <Links href="#" className="px-[48px]">
          EN
        </Links>
      </div> */}
    </div>
  );
};

export default MobileMenu;
