import React from 'react';
import {Provider} from 'react-redux';
import App from 'next/app';
import Layout from '@/layout';
import {wrapper} from '@/store';
import {setupListeners} from '@reduxjs/toolkit/dist/query';
import '@/styles/global.css';
import '@/styles/main.scss';
import 'keen-slider/keen-slider.min.css';
import {LanguageProvider} from '@/hooks/LanguageContext';
import {useRouter} from 'next/router';
import Head from 'next/head';

function ProjectApp({Component, pageProps, isMobile}) {
  const {store} = wrapper.useWrappedStore(pageProps);
  const router = useRouter();
  setupListeners(store.dispatch);
  const isNoIndexPage = router.asPath === '/calisan-adayi-kvkk';

  return (
    <Provider store={store}>
      <React.StrictMode>
        <LanguageProvider>
          <Layout>
            <Component {...pageProps} isMobile={isMobile} />
            <Head>
              <meta
                name="robots"
                content={isNoIndexPage || process.env.NEXT_PUBLIC_APP_MODE !== 'production' ? 'noindex, nofollow' : 'index, follow'}
              />
            </Head>
          </Layout>
        </LanguageProvider>
      </React.StrictMode>
    </Provider>
  );
}

ProjectApp.getInitialProps = async appContext => {
  const appProps = await App.getInitialProps(appContext);
  const isMobile = (appContext.ctx.req ? appContext.ctx.req.headers['user-agent'] : navigator.userAgent)?.match(
    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
  );
  return {...appProps, isMobile};
};

export default ProjectApp;
